<template>
    <div id="dropdown-component">

        <div v-if="params.data.isMatched || params.data.isDeleted">
            <b-dropdown id="dropdown-grouped" size="sm" variant="outline-primary"
                class="dropdown-icon-wrapper custom-dropdown btn-icon">
                <template #button-content>
                    <feather-icon icon="ArrowDownCircleIcon" size="12" class="align-middle" />
                </template>
                <b-dropdown-item variant="secondary" @click="params.onButtonClick(params.data, 'View')"> <feather-icon
                        icon="EyeIcon" size="18" /> View </b-dropdown-item>
            </b-dropdown>
        </div>

        <div v-else-if="params.data.isNew">
            <b-dropdown id="dropdown-grouped" size="sm" variant="outline-primary"
                class="dropdown-icon-wrapper custom-dropdown btn-icon">
                <template #button-content>
                    <feather-icon icon="ArrowDownCircleIcon" size="12" class="align-middle" />
                </template>
                <b-dropdown-item variant="secondary" @click="params.onButtonClick(params.data, 'Match')"> <feather-icon
                        icon="GitMergeIcon" size="18" /> Match </b-dropdown-item>
                <b-dropdown-item variant="secondary" @click="params.onButtonClick(params.data, 'Create')"> <feather-icon
                        icon="PlusCircleIcon" size="18" /> Create </b-dropdown-item>
            </b-dropdown>
        </div>

        <div v-else>
            <b-dropdown id="dropdown-grouped" size="sm" variant="outline-primary"
                class="dropdown-icon-wrapper custom-dropdown btn-icon">
                <template #button-content>
                    <feather-icon icon="ArrowDownCircleIcon" size="12" class="align-middle" />
                </template>
                <b-dropdown-item variant="secondary" @click="params.onButtonClick(params.data, 'Check')"> <feather-icon
                        icon="GitPullRequestIcon" size="18" /> Check Match </b-dropdown-item>
            </b-dropdown>
        </div>

    </div>
</template>
  
<script>

export default {
};

</script>
  
<style scoped>
.custom-dropdown {
    position: static !important;
    display: block !important;
    z-index: 9999;
}
</style>
  