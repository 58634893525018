<template>
    <div id="supervisor-family">

        <b-modal centered size="sm" ref="matching-modal" hide-footer :title="selectedItem.Description">
            <b-form-input placeholder="Insérer le code de l'article correspondant" ref="item-no-input"
                v-model="itemCode" type="text" @keyup.enter="handleOk"></b-form-input>
            <div class="d-block text-center">
                <b-button variant="relief-success" class="mt-1" @click="handleOk">Valider</b-button>
            </div>
        </b-modal>

        <b-modal centered size="sm" ref="item-modal" hide-footer :title="selectedItem.Description">
            <div>

                <div><strong>Catalog Item:</strong> {{ selectedItem.Catalog_Item }}</div>
                <div><strong>Category:</strong> {{ selectedItem.Category }}</div>
                <div><strong>Category No:</strong> {{ selectedItem.CategoryNo }}</div>
                <div><strong>Classe Thérapetique:</strong> {{ selectedItem.Classe_Thérapetique }}</div>
                <div><strong>Code_PCT:</strong> {{ selectedItem.Code_PCT }}</div>
                <div><strong>DCI:</strong> {{ selectedItem.DCI }}</div>
                <div><strong>DLC:</strong> {{ selectedItem.DLC }}</div>
                <div><strong>Description:</strong> {{ selectedItem.Description }}</div>
                <div><strong>Family:</strong> {{ selectedItem.Family }}</div>
                <div><strong>FamilyNo:</strong> {{ selectedItem.FamilyNo }}</div>
                <div><strong>Location_Code:</strong> {{ selectedItem.Location_Code }}</div>
                <div><strong>Matrice:</strong> {{ selectedItem.Matrice }}</div>
                <div><strong>No:</strong> {{ selectedItem.No }}</div>
                <div><strong>Nom_Fournisseur:</strong> {{ selectedItem.Nom_Fournisseur }}</div>
                <div><strong>Prix_Public:</strong> {{ selectedItem.Prix_Public }}</div>
                <div><strong>Subfamily:</strong> {{ selectedItem.Subfamily }}</div>
                <div><strong>SubfamilyNo:</strong> {{ selectedItem.SubfamilyNo }}</div>
                <div><strong>Unit_Price_pharmacien:</strong> {{ selectedItem.Unit_Price_pharmacien }}</div>
                <div><strong>Unit_Price_pharmacien_TTC:</strong> {{ selectedItem.Unit_Price_pharmacien_TTC }}</div>
                <div><strong>TVA:</strong> {{ selectedItem.VAT_Prod_Posting_Group }}</div>

            </div>
        </b-modal>

        <b-overlay :show="showLoading" spinner-variant="primary">

            <b-card>

                <b-row>
                    <b-col>
                        <b-row class="col">
                            <b-avatar variant="light-primary" rounded>
                                <feather-icon icon="ListIcon" size="18" />
                            </b-avatar>
                            <div class="ml-1">
                                <small>Total</small>
                                <h5 class="mb-0">
                                    <b-link @click="agGrid.rows = allItemsRows" class="font-weight-bold text-primary"
                                        style="border-bottom: 1px solid ;">{{
                                            kFormatter(allItemsRows.length) }}</b-link>
                                </h5>
                            </div>
                            <b-avatar variant="light-success" rounded class="ml-1">
                                <feather-icon icon="CheckIcon" size="18" />
                            </b-avatar>
                            <div class="ml-1">
                                <small>Matched Items</small>
                                <h5 class="mb-0">
                                    <b-link @click="agGrid.rows = matchedItemsRows"
                                        class="font-weight-bold text-primary" style="border-bottom: 1px solid ;">{{
                                            kFormatter(matchedItems) }}</b-link>
                                </h5>
                            </div>
                            <b-avatar variant="light-warning" rounded class="ml-1">
                                <feather-icon icon="PlusIcon" size="18" />
                            </b-avatar>
                            <div class="ml-1">
                                <small>New Items</small>
                                <h5 class="mb-0">
                                    <b-link @click="agGrid.rows = newItemsRows" class="font-weight-bold text-primary"
                                        style="border-bottom: 1px solid ;">{{
                                            kFormatter(newItems) }}</b-link>
                                </h5>
                            </div>
                            <b-avatar variant="light-danger" rounded class="ml-1">
                                <feather-icon icon="DeleteIcon" size="18" />
                            </b-avatar>
                            <div class="ml-1">
                                <small>Deleted Items</small>
                                <h5 class="mb-0">
                                    <b-link @click="agGrid.rows = deletedItemsRows"
                                        class="font-weight-bold text-primary" style="border-bottom: 1px solid ;">{{
                                            kFormatter(deletedItems) }}</b-link>
                                </h5>
                            </div>
                        </b-row>
                    </b-col>
                    <b-col cols="1" class="text-right">
                        <b-dropdown id="dropdown-grouped" variant="outline-primary"
                            class="dropdown-icon-wrapper btn-icon">
                            <template #button-content>
                                <feather-icon icon="SettingsIcon" size="12" class="align-middle spinner" />
                            </template>
                            <b-dropdown-item variant="secondary" @click="checkMatch">
                                <feather-icon icon="GitPullRequestIcon" size="18" /> Check Match </b-dropdown-item>
                        </b-dropdown>
                    </b-col>
                    <b-col>
                        <b-form-input @input="updateSearchQuery" placeholder="Recherche" type="text"
                            class="d-inline-block" />
                    </b-col>
                </b-row>
                <ag-grid ref="agGrid" :agGrid="agGrid" pageTitle="Company Items List" @refresh="loadData"
                    :withFilters="false" @cellDoubleClicked="showDetails" :showHeader="false"
                    @onFirstDataRendered="onFirstDataRendered" />
            </b-card>
        </b-overlay>

    </div>
</template>

<script>
import AgGrid from "@/views/components/ag-grid/AgGrid.vue"
import BLinkCellRenderer from "@/views/components/ag-grid/BLinkCellRenderer.vue"
import {
    kFormatter
} from '@core/utils/filter'
import CompanyItemDropDown from '@/views/components/ag-grid/CompanyItemDropDown.vue';

export default {
    components: {
        AgGrid,
    },
    data() {
        return {
            itemCode: "",
            matchedItems: 0,
            newItems: 0,
            deletedItems: 0,
            kFormatter: kFormatter,
            showLoading: false,
            agGrid: {
                columnDefs: [
                    // {
                    //     headerCheckboxSelection: true,
                    //     checkboxSelection: true,
                    //     width: 50,
                    //     filter: false,
                    //     headerClass: 'centered-checkbox',
                    // },
                    {
                        headerName: "Code Grossite",
                        field: "No",
                    },
                    {
                        headerName: "PCT",
                        field: "Code_PCT",
                    },
                    {
                        headerName: "Description",
                        field: "Description",
                        cellRendererFramework: 'bLinkCellRenderer',
                        cellRendererParams: { onButtonClick: this.showDetails, },
                    },
                    {
                        headerName: "Type",
                        field: "Family",
                    },
                    {
                        headerName: "Fournisseur",
                        field: "Nom_Fournisseur",
                    },
                    {
                        headerName: "Code Portail",
                        field: "code",
                    },
                    {
                        headerName: '',
                        cellClass: 'centered-customeCellRender',
                        cellRendererFramework: 'companyItemDropDown',
                        cellRendererParams: { onButtonClick: this.onButtonClick },
                        sortable: false,
                        resizable: false,
                        filter: false,
                        width: 80
                    },
                ],
                rows: []
            },
            allItemsRows: [],
            matchedItemsRows: [],
            newItemsRows: [],
            deletedItemsRows: [],
            selectedItem: "",
        }
    },
    created() {
        this.$options.components.companyItemDropDown = CompanyItemDropDown;
        this.$options.components.bLinkCellRenderer = BLinkCellRenderer;
        this.loadData();
    },
    methods: {
        async showDetails(data) {
            this.selectedItem = data.No == undefined ? data.data : data;
            this.$refs['item-modal'].show();
        },
        async onButtonClick(data, action) {
            switch (action) {
                case "View":
                    window.open("https://www.pharmaconnect.net/supervisor/item/" + data.code, '_blank');
                    break;
                case "Match":
                    this.selectedItem = data;
                    this.itemCode = "";
                    this.$refs['matching-modal'].show();
                    setTimeout(() => {
                        this.$refs["item-no-input"].focus();
                    }, 400);
                    break;
                case "Create":
                    localStorage.setItem('dataToPass', JSON.stringify(data));
                    if (data.FamilyNo == "MEDICAMENT") {
                        window.open("https://www.pharmaconnect.net/supervisor/item/MED", '_blank');
                    } else {
                        window.open("https://www.pharmaconnect.net/supervisor/item/PARA", '_blank');
                    }
                    break;
                case "Check":
                    await this.handleCheckAction(data);
                    break;
            }
        },
        async handleOk() {
            this.$refs['matching-modal'].hide();
            this.showLoading = true;
            let obj = {
                company: this.$route.params.slug,
                itemNo: this.selectedItem.No,
                itemCode: this.itemCode,
            }
            let response = await this.$http.post("_item/matchItem", obj).catch(() => {
                this.showLoading = false;
            });
            let itemIndex = this.agGrid.rows.findIndex(item => item.No === this.selectedItem.No);
            this.$set(this.agGrid.rows, itemIndex, {
                ...this.agGrid.rows[itemIndex],
                code: response.data.itemCode,
                isMatched: true,
            });
            this.showLoading = false
        },
        async handleCheckAction(data) {
            this.showLoading = true;
            try {
                let resp = await this.$http.get("_item/getItemCodeByItemNo/" + data.No, { params: { companyId: this.$route.params.slug } });
                let itemIndex = this.agGrid.rows.findIndex(item => item.No === data.No);
                if (resp.data !== "") {
                    this.$set(this.agGrid.rows, itemIndex, {
                        ...this.agGrid.rows[itemIndex],
                        code: resp.data,
                        isMatched: true,
                    });
                    this.showLoading = false;
                } else {
                    this.$set(this.agGrid.rows, itemIndex, {
                        ...this.agGrid.rows[itemIndex],
                        isNew: true
                    });
                    this.showLoading = false;
                }
            } catch (error) {
                console.error("Error checking item:", error);
                this.showLoading = false;
            }
        },
        async checkMatch() {
            this.matchedItems = 0;
            this.deletedItems = 0;
            this.newItems = 0;
            this.matchedItemsRows = [];
            this.newItemsRows = [];
            this.deletedItemsRows = [];
            this.showLoading = true;
            let response = await this.$http.get("_item/checkMatch/" + this.$route.params.slug).catch(() => this.showLoading = false);
            if (response.data.length > 0) {
                response.data.forEach(element => {
                    let item = this.agGrid.rows.find(item => item.No === element.itemNo);
                    if (item) {
                        item["isMatched"] = true;
                        this.matchedItemsRows.push(item);
                        item["code"] = element.itemCode;
                    } else {
                        this.deletedItemsRows.push({ No: element.itemNo, code: element.itemCode, isDeleted: true });
                    }
                });
            }
            this.newItemsRows = this.agGrid.rows.filter(item => item.code == undefined);

            this.newItemsRows.forEach(obj => {
                obj.isNew = true;
            });
            this.newItems = this.newItemsRows.length;
            this.matchedItems = this.matchedItemsRows.length;
            this.deletedItems = this.deletedItemsRows.length;

            this.showLoading = false;
        },
        async loadData() {
            this.showLoading = true;
            let response = await this.$http.get("item/findAllByCompanyId/" + this.$route.params.slug).catch(() => this.showLoading = false);
            // console.log(response);
            this.agGrid.rows = response.data;
            this.allItemsRows = response.data;
            this.showLoading = false;
        },
        updateSearchQuery(val) {
            const gridApi = this.$refs.agGrid.gridApi;
            gridApi.setQuickFilter(val);
        },
        onFirstDataRendered() {
            this.$refs.agGrid.gridOptions.api.sizeColumnsToFit();
        }
    },
}
</script>
<style lang="scss" scoped></style>